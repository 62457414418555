:root {
    --textColor: #606060;
    /* --textColor: #0affef; */
    /* --backgroundColor: #e6edf8; */
    /* --shadowColorDark: #c4c9d3; */
    --backgroundColor: #e0e0e0;
    --shadowColorDark: #bebebe;
    --shadowColorLight: #ffffff;
    /* --backgroundColor: #3e4751;
    --shadowColorDark: #353c45;
    --shadowColorLight: #47525d; */
    --boxShadowStyle: .6vmin .6vmin 1.8vmin var(--shadowColorDark), -.6vmin -.6vmin 1.8vmin var(--shadowColorLight);
    --boxShadowStyleInset: inset .6vmin .6vmin 1.8vmin var(--shadowColorDark), inset -.6vmin -.6vmin 1.8vmin var(--shadowColorLight);
    --boxShadowStyleHover: .8vmin .8vmin 1.8vmin var(--shadowColorDark), -.8vmin -.8vmin 1.8vmin var(--shadowColorLight);
    --borderRadius: 2vmin;

}

body {
    background-color:var(--backgroundColor);
    font-family:'Baloo Thambi 2', Arial, Helvetica, sans-serif;
    font-weight: 600;
    max-height: 90vh;
}

.customCard {
    color: var(--textColor);
    margin-top: 4vmin; 
    margin-bottom: 4vmin; 
    border-width: 0; 
    background-color: var(--backgroundColor); 
    box-shadow: var(--boxShadowStyle);
    border-radius: var(--borderRadius);
}

.customCard:hover {
    /* background-color: rgb(228, 226, 226) !important; */
    box-shadow: var(--boxShadowStyleHover) !important
}

.buttonContainerStyle {
    margin-bottom: 2vh; 
    margin-top: 2vh;
    justify-content: center;
    background-color: var(--backgroundColor);
    border-radius: var(--borderRadius);
}

.buttonContainerStyle2 {
    background-color: var(--backgroundColor);
    justify-content: center;
    max-height: 82vh;
    border-radius: var(--borderRadius);
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.buttonContainerStyle2::-webkit-scrollbar {
    display: none;
}

.buttonStyleClicked {
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 1%; 
    margin-right: 4vmin;
    background-color: var(--backgroundColor) !important;
    border-width: 0 !important;
    color: var(--textColor) !important; 
    box-shadow: var(--boxShadowStyleInset) !important;
    border-radius: var(--borderRadius);
    font-weight: 600;
}

.buttonStyleNotClicked {
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 1%; 
    margin-right: 4vmin;
    background-color: var(--backgroundColor) !important; 
    border-width: 0 !important; 
    color: var(--textColor) !important;
    box-shadow: var(--boxShadowStyle) !important;
    border-radius: var(--borderRadius);
    font-weight: 600;
}

.buttonStyleNotClicked:hover{
    box-shadow: var(--boxShadowStyleHover);
}

.buttonStyleNotClicked:active{
    box-shadow: var(--boxShadowStyleHover);
}

.buttonStyleNotClicked2 {   
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 1%; 
    background-color: var(--backgroundColor) !important; 
    border-width: 0; 
    color: var(--textColor) !important; 
    box-shadow: var(--boxShadowStyle);
    border-radius: var(--borderRadius);
    font-weight: 600;
}

.buttonStyleNotClicked2:hover{
    box-shadow: var(--boxShadowStyleHover);
}

.buttonStyleNotClicked2:focus{
    box-shadow: var(--boxShadowStyle);
}

.buttonStyleNotClicked2:active{
    box-shadow: var(--boxShadowStyleHover) !important;
}

.rowStyle {
    margin: 1%;
    padding-left: 2%;
}

.rowStyleSmall {
    margin-left: 1%;
    padding-left: 2%;
}

.columnStyle {
    padding: 0%;
}

.ModalStyle .modal-content {
    background-color: var(--backgroundColor);
    color: var(--textColor);
}